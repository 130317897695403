.airdrop-pk-input {
  width: 100%; 
  margin-top: 10px !important; 
  margin-bottom: 40px !important; 
  text-transform: none !important; 

  &:disabled {
    opacity: 0.3; 
  }
}

.network-dropdown {
  font-size: 12px !important;
  padding: 0;
  margin-bottom: 40px;
  margin-top: 10px;
  width: 100%;
}

.network-dropdown-wrapper {
  width: 500px;
}

.airdrop-container {
  height: calc(100vh - 70px);
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.airdrop-wrapper {
  border: 1px solid black;
  padding: 40px;
  display: flex;
  max-width: 500px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  h3 {
    width: 100%;
    margin: 0;
  }

  p {
    font-size: 12px;
    width: 100%;
    margin: 0;
  }
}

.sol-airdrop {
  width: 100% !important;
  margin-top: 10px !important;
  margin-bottom: 40px !important;
}

.stake-input {
  flex-grow: 2;
  margin-left: 0 !important;

  &:disabled {
    opacity: 0.3; 
  }
}
.form-row {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-top: 10px;
  margin-bottom: 40px;

  input {
    margin-left: 10px;
  }
}
